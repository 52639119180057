import { useEffect, useState } from "react"
import { RestoreStepOne } from "./RestoreStepOne";
import { RestoreStepTwo } from "./RestoreStepTwo";
import { RestoreStepThree } from "./RestoreStepThree";
import { useNavigate, useParams } from "react-router-dom";
import { settings } from "../../settings";
import { CheckIcon } from "@heroicons/react/24/outline";
import {ErrorPage} from "./../../kit/ErrorPage";

const steps = [
    { id: 0, name: "Select source" },
    { id: 1, name: "Select target" },
    { id: 2, name: "Restoring" }
]


export const RestorePage = () => {

    const [currentId, setCurrentId] = useState(0);
    const [user, setUser] = useState(null);
    const [repo, setRepo] = useState(null);
    const [backup, setBackup] = useState(null);

    const [pageError, setPageError] = useState(null);


    const [targetOwner, setTargetOwner] = useState('');
    const [targetRepo, setTargetRepo] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);

    const changeUrl = (backup, repo, user) => {
        if (backup !== null) {
            window.history.replaceState(null, document.title, `${settings.currentUrl}/restore/${user}/${repo.id}/${backup.id}`)
        }
        else if (repo !== null && user !== null) {
            window.history.replaceState(null, document.title, `${settings.currentUrl}/restore/${user}/${repo.id}`)
        }
        else if (user !== null) {
            window.history.replaceState(null, document.title, `${settings.currentUrl}/restore/${user}`)
        }
    }

    useEffect(() => {
        document.title = "Cloudback: Restore"
    }, [])

    const onSetUser = (newUser) => {
        setUser(newUser);
        if (newUser !== null) {
            setTargetOwner(newUser);
        }
    }

    const onSetRepo = (newRepo) => {
        setRepo(newRepo);       
        if (newRepo !== null) {
            setTargetRepo(newRepo.label+"-restored");
            setIsPrivate(newRepo.isPrivate);
        }   
    }

    const onSetBackup = (backup) => {
        setBackup(backup);
        changeUrl(backup, repo, user);
    }

    const onNext = () => {
        setCurrentId(x => x + 1);
    }

    const onBack = () => {
        setCurrentId(x => x - 1);
    }

    const navigate = useNavigate();

    const { accountName, repoId, backupId } = useParams();
    return pageError !==null ? <ErrorPage />: (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-3xl">
                <RestoreStepperHeader currentId={currentId} />
                <div className="w-full h-full divide-y divide-gray-200 rounded-lg bg-white shadow-sm shadow-gray-400 px-4 mt-10 py-4">
                    {currentId === 0 && <RestoreStepOne
                        setPageError={setPageError}
                        initialBackupId={backupId}
                        initialRepoId={repoId}
                        initialUserName={accountName}
                        user={user} setUser={onSetUser}
                        repo={repo} setRepo={onSetRepo}
                        backup={backup} setBackup={onSetBackup}
                        onNext={onNext}
                        onCancel={() => navigate(-1)}
                    />}
                    {currentId === 1 && <RestoreStepTwo
                        setPageError={setPageError}
                        targetOwner={targetOwner} setTargetOwner={setTargetOwner}
                        targetRepo={targetRepo} setTargetRepo={setTargetRepo}
                        isPrivate={isPrivate} setIsPrivate={setIsPrivate}
                        onNext={onNext}
                        onBack={onBack}
                    />}
                    {currentId === 2 && <RestoreStepThree
                        setPageError={setPageError}
                        restoreData={{
                            defId: repo.defId,
                            targetOwner: targetOwner,
                            targetRepo: targetRepo,
                            sourceOwner: user,
                            sourceRepo: repo.label,
                            isPrivate: isPrivate,
                            backupId: backup.id
                        }}
                        onBack={onBack}
                    />}
                </div>
            </div>
        </div>
    )
}

const RestoreStepperHeader = ({ currentId }) => {

    return (
        <nav aria-label="Progress">
            <ol role="list" className="flex space-x-8 space-y-0">
                {steps.map((step, i) => (
                    <li key={step.name} className="flex-1">
                        {i < currentId ? (
                            <div
                                className="group flex justify-center border-l-4 border-gray-600 py-1 pl-4 hover:border-gray-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                            >
                                <CheckIcon className="h-5 w-5 text-gray-700"/>
                                <span className="text-sm font-medium group-hover:text-gray-800">Step {step.id + 1}: {step.name}</span>
                            </div>
                        ) : i === currentId ? (
                            <div
                                className="flex justify-center border-l-4 border-gray-600 py-1 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                aria-current="step"
                            >
                                <span className="text-sm font-medium">Step {step.id + 1}: {step.name}</span>
                            </div>
                        ) : (
                            <div
                                className="group flex justify-center border-l-4 border-gray-200 py-1 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                            >
                                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">Step {step.id + 1}: {step.name}</span>
                            </div>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}