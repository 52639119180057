import { AzureSubscriptionActivatedAlert } from "./AzureSubscriptionActivatedAlert"
import { AzureSubscriptionErrorAlert } from "./AzureSubscriptionErrorAlert"
import { MarketplacePlanMissingDialogAlert } from "./MarketplacePlanMissingDialogAlert"

export const TopAlerts = {
    MarketplacePlanMissing: "MarketplacePlanMissing",
    AzureSubscriptionActivated: "AzureSubscriptionActivated",
    AzureSubscriptionError: "AzureSubscriptionError",
}

export const TopAlertsContent = {
    [TopAlerts.MarketplacePlanMissing]: MarketplacePlanMissingDialogAlert,
    [TopAlerts.AzureSubscriptionActivated]: AzureSubscriptionActivatedAlert,
    [TopAlerts.AzureSubscriptionError]: AzureSubscriptionErrorAlert
}