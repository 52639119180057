import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom"
import { addTopAlert, closeModal, closeTopAlert } from "../../slices/userSlice";
import { TopAlerts, TopAlertsContent } from "./topAlerts/TopAlertsCommon";
import { ModalDialog } from "../../kit/Modal";
import { ModalsContent } from "./modals/ModalsCommon";
import axios from "axios";
import { settings } from "../../settings";
import { processAxiosError } from "../util/ErrorBoundary";

const TopAlertComponent = (props) => {
    const dispatch = useDispatch();
    const AlertInnerComponent = props.dialog !== undefined ? TopAlertsContent[props.dialog.id] : null;
    return (
        <>
            {props.dialog !== undefined &&
                <AlertInnerComponent
                    content={props.dialog}
                    onClose={() => dispatch(closeTopAlert(props.dialog))} />
            }
        </>
    )
}
export const TopAlertStack = () => {

    const topAlerts = useSelector(state => state.user.topAlerts);
    const topAlertsArray = useMemo(() => Object.values(topAlerts).slice(0, 3), [topAlerts]);
    const dispatch = useDispatch();
    //Load alerts
    const loadAlerts = ()=>{
        axios.get(settings.backendUrl+"/v2/accountsWithoutPlan")
        .then(res=>{
            if (res.data.isSuccess && res.data.result.length > 0){
                dispatch(addTopAlert({id: TopAlerts.MarketplacePlanMissing, accounts: res.data.result}));
            }
        })
        .catch(e=>{
            processAxiosError(e);
        })
        
    }

    useEffect(()=>{
        loadAlerts();    
    }, []);

    return topAlertsArray.length === 0 ? <></> : (
        <div className="w-full">
            {topAlertsArray.map((x, i) => <TopAlertComponent key={i} dialog={x} />)}
        </div>
    )
}

export const ModalOutlet = () => {

    const modal = useSelector(state => state.user.modal);
    const ModalInnerComponent = modal !== null ? ModalsContent[modal.id] : null;
    const dispatch = useDispatch();
    const onModalClose = () => {
        dispatch(closeModal());
    }

    useEffect(() => {
      try {
        window.gtag('event', 'conversion', {'send_to': 'AW-364234234/3zblCPXBlaMDEPqL160B'});
      } catch (e) {
        console.log("Error in gtag conversion event", e);
      }
    }, []);

    return (
        <div className="relative min-w-full h-full">
            <div className="absolute top-0 left-0 h-full w-full">
                <Outlet />
                {modal !== null &&
                    <ModalDialog onClose={onModalClose}>
                        <ModalInnerComponent onClose={onModalClose} data={modal}/>
                    </ModalDialog>
                }
            </div>
        </div>
    )
}