import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { FullScreenLoader } from "../../kit/Spinners";
import { addTopAlert } from "../../slices/userSlice";
import { TopAlerts } from "../application/topAlerts/TopAlertsCommon";
import { useNavigate, useSearchParams } from "react-router-dom";

export const AzureSubscriptionAlert = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [queryParams, setQueryParams] = useSearchParams();

    useEffect(()=>{
        let error = queryParams.get("error");     
        dispatch(addTopAlert({ id: error ? TopAlerts.AzureSubscriptionError : TopAlerts.AzureSubscriptionActivated, error }));
        navigate("/");
    }, [queryParams])

    return <FullScreenLoader />;
}