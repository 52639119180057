import axios from "axios";
import { useConnection } from './hooks/signals/connector'
import ErrorBoundary from "./components/util/ErrorBoundary";
import { useNotifications } from "./hooks/notifications/notifier";
import { Outlet, useLocation } from "react-router-dom";
import { LeftNavBar } from "./components/application/LeftNavBar";
import { ApplicationHeader } from "./components/application/ApplicationHeader";
import { useUser } from "./hooks/useUser";
import { ReactComponent as CloudbackLogo } from './assets/logo-black.svg'
import { useState } from "react";
import { TopAlertStack } from "./components/application/ModalOutlet";
import { SignInForm } from "./components/predashboard/SignInWindow";
import { InitialSetup } from "./components/predashboard/InitialSetup";
import { FullScreenLoader } from "./kit/Spinners";
import { OtpWindow } from "./components/predashboard/OtpWindow";
import { AccountDeleted } from "./components/application/AccountDeleted";

const DashboardLoader = () => {
  return (
    <div className='h-screen flex flex-col justify-center items-center'>
      <div className="mx-auto w-full">
        <CloudbackLogo className="mx-auto h-10 w-10" />
      </div>
      <div className='flex flex-col mt-10'>
        <FullScreenLoader />
      </div>
    </div>
  )
}

function App() {
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(function (response) {
    if (response?.data?.errorCode === "unauthorized") {
      window.location.href = "/Auth/SignOut";
    }
    return response;
  });

  useConnection();
  useNotifications();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, userError, notifyError, setupRequired] = useUser();

  const location = useLocation();

  return (
    <ErrorBoundary notifyError={notifyError}>
      {
        location.pathname === '/signin-otp' ? <OtpWindow /> :
        location.pathname === '/AccountDeleted' ? <AccountDeleted />:
          isLoading ?
            <DashboardLoader /> :
            userError ?
              <SignInForm /> :
              setupRequired ? <InitialSetup /> :
                <>
                  <LeftNavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                  <div className="lg:pl-72 h-screen flex flex-col">
                    <TopAlertStack />
                    <ApplicationHeader setSidebarOpen={setSidebarOpen} />
                    <main className="flex-1 overflow-y-auto ">
                      <div className="h-full bg-gray-100">
                        <Outlet />
                      </div>
                    </main>
                  </div>
                </>
      }
    </ErrorBoundary>
  )
}

export default App;
