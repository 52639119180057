import { BoltIcon, CalendarIcon, DevicePhoneMobileIcon, DocumentIcon, ExclamationTriangleIcon, GlobeAltIcon, UserIcon, UserPlusIcon, WifiIcon, FingerPrintIcon } from "@heroicons/react/24/outline"
import { BackupInfoBlock } from "../../repository/RepositoryDetails"
import { classNames } from "../ApplicationHeader"
import { capitalizeFirstLetter } from "../../../helpers/internalFunctions"
import { intToIP } from "../../settings/AuditLog"
import { StatLabel } from "../../../kit/DashboardCard"

const Statement = ({ title, value, Icon, valueLink, isLast }) => {

    return (
        <div className={classNames("flex flex-row items-center align-middle py-1 w-full overflow-hidden", isLast ? "" : "border-b border-gray-100/2 ")}>
            {Icon !== undefined && <Icon className="h-5 w-5 min-h-5 min-w-5 mr-1" size="medium" />}
            <div className="flex flex-row justify-start w-full items-center overflow-hidden">
                {title !== undefined && <StatLabel className="text-xs w-1/4" title={title} />}
                <p className="break-all w-3/4 ml-1 items-center text-md font-medium text-left text-xs">{value === undefined? "-":value}</p>
            </div>
        </div>
    )
}

export const AuditLogInfoModal = ({ onClose, data }) => {
    const jsonFields = data.tags.filter(x => x.tagKey === "fields")[0]?.tagValue;
    
    const fields = jsonFields !== undefined ? JSON.parse(jsonFields) : [];
    return (
        <div className={classNames("br-2 w-96 rounded-lg bg-white flex flex-col flex-1")}>
            <div className="flex flex-col sm:flex-row gap-2 justify-between mb-4">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Audit Log Record Info</h3>
            </div>
            <div className="flex flex-col gap-1">
                <div className="flex flex-col gap-1 flex-1 overflow-hidden">
                    <Statement title="Time" value={new Date(data.timestamp).toLocaleString()} Icon={CalendarIcon} />
                    <Statement title="Action" value={capitalizeFirstLetter(data.actionType)} Icon={BoltIcon} />
                    <Statement title="Message" value={data.formattedMessage} Icon={DocumentIcon} />
                    <Statement title="Account" value={data.linkedAccount?.linkedAccountId} Icon={UserPlusIcon} />
                    <Statement title="User" value={data.userInfo?.userSettings?.name} Icon={UserIcon} />
                    <Statement title="IP" value={intToIP(data.userInfo?.userIp)} Icon={WifiIcon} />
                    <Statement title="Location" value={data.userInfo?.userLocation?.location} Icon={GlobeAltIcon} />
                    <Statement title="Device" value={data.userInfo?.userDevice?.device} Icon={DevicePhoneMobileIcon} />
                    <Statement title="CorrelationId" value={data.correlationId} Icon={FingerPrintIcon}/>
                    <Statement title="Error" value={data.error === null || data.error === undefined ? "-":
                        `${data.error?.errorCode}: ${data.error?.errorMessage}`} Icon={ExclamationTriangleIcon} />
                </div>
                {Object.keys(fields).length > 0 &&
                    <table className="mt-2 min-w-full divide-y divide-gray-300 shadow ring-1 ring-black ring-opacity-5 rounded-t-lg text-xs">
                        <thead className="bg-gray-50 h-5 font-semibold">
                            <tr>
                                <th className="pl-2">Field</th>
                                <th>Before</th>
                                <th>After</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {Object.keys(fields).map((fKey, index) => (
                                <tr key={fKey} className="border-b border-gray-200">
                                    <td className="pl-2">{fKey}</td>
                                    <td>{fields[fKey].before}</td>
                                    <td>{fields[fKey].after}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
            <div className="mt-5 sm:mt-5 sm:flex sm:flex-row-reverse">
                {onClose && <button type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:w-auto"
                    onClick={onClose}>
                    Close
                </button>}
            </div>
        </div>
    )
}